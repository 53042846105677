<template>
  <div class="wrapper">
    <div :style="'overflow: auto; height: calc(100vh - 225px); overflow-y:scroll'" class="contentContact">
      <v-expand-transition>
        <router-view  />
      </v-expand-transition>
    </div>
  </div>
</template>
<script>


export default {
  name:"Contacts",
  components: {

  },
  beforeRouteLeave (to, from, next) {
      this.$store.dispatch('base/SET_SUB_MENU', []);
      this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
      this.operationToDisplay = {};
      next();
  },
  data() {
    return {

    }
  },
  methods: {
  },
  computed: {
      
  },
  mounted() {
  },

}
</script>
